<template>
  <div class="merchant-zone">
    <div class="banner">
      <div class="container">
        <strong>{{ $t(`MerchantZone['你的经营神器']`) }}</strong>
        <p>{{ $t(`MerchantZone['生意的最佳伙伴']`) }}</p>
      </div>
    </div>
    <div class="apply">
      <p>
        {{ $t(`MerchantZone['立即申请成为U-Pay 商户，为您带来更多生意']`) }}
      </p>
      <button @click="applicationDialogVisible = true">
        {{ $t(`MerchantZone['点击申请']`) }}
      </button>
    </div>
    <div id="benefit" class="advantage">
      <div class="container">
        <h2>{{ $t(`MerchantZone['U-Pay 合作商户优势']`) }}</h2>
        <i class="iconfont el-icon-arrow-down"></i>
        <ul class="list">
          <li v-for="item in advantage" :key="item.topic">
            <img :src="item.img" :alt="item.topic" />
            <strong>{{ $t(`MerchantZone['${item.topic}']`) }}</strong>
            <p>{{ $t(`MerchantZone['${item.text}']`) }}</p>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      class="application-dialog"
      @close="applicationDialogClose"
      :title="$t(`MerchantZone['申请列表']`)"
      :visible.sync="applicationDialogVisible"
      width="700px"
      :fullscreen="isFullscreen"
    >
      <el-form
        class="form"
        ref="applicationForm"
        :model="applicationForm"
        label-width="220px"
        :rules="applicationFormRule"
        :label-position="labelPosition"
      >
        <el-form-item
          v-for="item in applicationFormList"
          :label="$t(`MerchantZone['${item.label}']`)"
          :prop="item.prop"
          :key="item.prop"
        >
          <el-select
            style="width: 100%"
            v-if="item.label === '产业类型'"
            v-model="applicationForm[item.prop]"
            :placeholder="$t(`MerchantZone['${item.placeholder}']`)"
          >
            <el-option-group
              v-for="group in typeOptions"
              :key="group.label"
              :label="$t(`type['${group.label}']`)"
            >
              <el-option
                v-for="item in group.options"
                :key="item.value"
                :label="$t(`type['${item.label}']`)"
                :value="$t(`type['${item.value}']`)"
              >
              </el-option>
            </el-option-group>
          </el-select>
          <el-select
            style="width: 100%"
            v-else-if="item.label === '城市'"
            v-model="applicationForm[item.prop]"
            :placeholder="$t(`MerchantZone['${item.placeholder}']`)"
          >
            <el-option
              v-for="item in cityOptions"
              :key="item.value"
              :label="$t(`Globle['${item.label}']`)"
              :value="$t(`Globle['${item.value}']`)"
            >
            </el-option>
          </el-select>
          <el-input
            v-else
            v-model="applicationForm[item.prop]"
            :placeholder="$t(`MerchantZone['${item.placeholder}']`)"
          ></el-input>
        </el-form-item>

        <el-form-item prop="dragVerify" :show-message="!isPassing">
          <DragVerify
            class="drag-verify"
            style="border: solid 1px rgb(220, 223, 230)"
            ref="dragVerify"
            :width="300"
            :isPassing.sync="isPassing"
            :text="$t(`MerchantZone['请拖动滑块完成验证']`)"
            :successText="$t(`MerchantZone['验证通过']`)"
            handlerIcon="el-icon-arrow-right"
            successIcon="el-icon-check"
          >
          </DragVerify>
        </el-form-item>
        <el-form-item class="btn-box">
          <el-button
            :loading="applicationLoading"
            type="primary"
            class="button"
            @click="onSubmit"
            >{{ $t("MerchantZone['申请']") }}</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      class="successDialogbox"
      :visible.sync="successDialogVisible"
      width="400px"
    >
      <img src="../assets/image/merchantZone/√-icon 背景@2x (1).png" alt="" />
      <p>{{ $t("MerchantZone['提交申请成功']") }}</p>
    </el-dialog>

    <Penel :title="$t(`MerchantZone['二维码类型']`)" class="code">
      <div class="code-box">
        <div v-for="(item, i) in code" :key="i" class="item">
          <h4 class="title">{{ $t(`MerchantZone['${item.title}']`) }}</h4>
          <img :src="item.img" alt="code type" />
          <p>{{ $t(`MerchantZone['${item.text}']`) }}</p>
        </div>
      </div>
    </Penel>

    <Penel
      :title="$t(`MerchantZone['商户使用流程简介']`)"
      class="paySteps common"
    >
      <h3>{{ $t(`MerchantZone['${"静态二维码付款"}']`) }}</h3>
      <ul class="use-steps">
        <li v-for="(item, i) in useSteps" :key="i">
          <template v-if="i % 2 === 0">
            <img :src="item[$i18n.locale + 'Img']" alt="upay use steps" />
            <strong>{{ $t(`Common['${item.topic}']`) }}</strong>
            <p>{{ $t(`MerchantZone['${item.text}']`) }}</p>
          </template>
          <template v-if="i % 2 === 1">
            <div class="arrow">
              <img src="../assets/image/merchantZone/红色箭头@2x.png" alt="" />
            </div>
          </template>
        </li>
      </ul>
      <h3>{{ $t(`MerchantZone['${"收款二维码"}']`) }}</h3>
      <p class="addition-text">
        {{
          $t(
            `MerchantZone['${"商户在U-Pay POS中输入金额，待收款二维码生成即可供客户扫描支付。"}']`
          )
        }}
      </p>

      <h3>{{ $t(`MerchantZone['${"扫码收款"}']`) }}</h3>

      <p class="addition-text">
        {{
          $t(
            `MerchantZone['${"商户在U-Pay 商户在U-Pay POS中输入金额，然后扫描客户钱包上的付款二维码即完成支付。"}']`
          )
        }}
      </p>
    </Penel>

    <Penel
      style="display: none"
      :title="$t(`MerchantZone['合作方案']`)"
      class="scheme"
    >
      <div class="items-box">
        <div v-for="item in 5" :key="item" class="item"></div>
      </div>
    </Penel>
    <Penel id="FAQs" :title="$t(`MerchantZone['常见问题']`)" class="problem">
      <el-collapse class="main" :name="i" v-for="i in 3" :key="i">
        <el-collapse-item>
          <template slot="title">
            <div class="title-text">
              {{ $t(`MerchantZone['problem_${i}']`) }}
            </div>
          </template>
          <div
            class="solution_text"
            v-html="$t(`MerchantZone['solution_${i}']`)"
          ></div>
        </el-collapse-item>
      </el-collapse>
      <!-- <ul>
        <li class="problen-item" v-for="i in 3" :key="i">
          <div class="topic">{{ $t(`MerchantZone['problem_${i}']`) }}</div>
          <div :class="{ open: false }" class="text">
            发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施发货计划实施
          </div>
        </li>
      </ul> -->
    </Penel>
  </div>
</template>

<script>
import emailjs from 'emailjs-com'
import DragVerify from 'vue-drag-verify2'
export default {
  name: 'MerchantZone',
  components: {
    DragVerify
  },
  computed: {
    advantage() {
      return [
        {
          img: require('../assets/image/merchantZone/支持多种付款方式@2x.png'),
          topic: '支持多种付款方式',
          text: '各行各业，不论规模，都可导入UPAY收款服务。'
        },
        {
          img: require('../assets/image/merchantZone/UPAY 行销方案支持增加品牌曝光率@2x.png'),
          topic: 'U-Pay 行销方案支持增加品牌曝光率',
          text:
            '透过U-Pay平台推送商品资讯及最新优惠，助力挖掘流量洼池，为用户提升更好的消费体验。'
        },
        {
          img: require('../assets/image/merchantZone/UPAY会员专属回馈@2x.png'),
          topic: 'U-Pay会员专属回馈',
          text:
            '有效提高订单量，助力拉动新顾客，以及老顾客的回头率，支援多种规则玩法和集点方式开发更多潜在消费群。'
        },
        {
          img: require('../assets/image/merchantZone/营业资讯精准掌握@2x.png'),
          topic: '营业资讯精准掌握',
          text: '透过UPOS 数据产出， 掌握大数据助精准营销，聪明管理有效率。'
        }
      ]
    },
    useSteps() {
      return [
        {
          zhImg: require('../assets/image/merchantZone/ScanQRCN.png'),
          enImg: require('../assets/image/merchantZone/ScanQREN.png'),
          khImg: require('../assets/image/merchantZone/ScanQRKH.png'),
          topic: 'Step 1',
          text: '顾客扫码'
        },
        {},
        {
          zhImg: require('../assets/image/merchantZone/QRPaymentCN.png'),
          enImg: require('../assets/image/merchantZone/QRPaymentEN.png'),
          khImg: require('../assets/image/merchantZone/QRPaymentKH.png'),
          topic: 'Step 2',
          text: '顾客输入金额'
        },
        {},
        {
          zhImg: require('../assets/image/merchantZone/PaymentCN.png'),
          enImg: require('../assets/image/merchantZone/PaymentEN.png'),
          khImg: require('../assets/image/merchantZone/PaymentKH.png'),
          topic: 'Step 3',
          text: 'paymentDetail'
        },
        {},
        {
          zhImg: require('../assets/image/merchantZone/PaySuccessCN.png'),
          enImg: require('../assets/image/merchantZone/PaySuccessEN.png'),
          khImg: require('../assets/image/merchantZone/PaySuccessKH.png'),
          topic: 'Step 4',
          text: '支付成功'
        }
        // {},
        // {
        //   img: require('../assets/image/default.png'),
        //   topic: 'Step 4',
        //   text: '商户可确认支付成功讯息'
        // }
      ]
    },
    code() {
      return [
        {
          title: '动态二维码',
          img: require('../assets/image/merchantZone/商家二维码@2x.png'),
          text: '由店家输入金额后产生二维码供顾客扫描'
        },
        {
          title: '静态二维码',
          img: require('../assets/image/merchantZone/扫码@2x.png'),
          text: '由顾客扫描店家固定二维码后输入支付金额'
        }
      ]
    },
    applicationFormList() {
      return [
        { label: '姓名', prop: 'name', placeholder: '请输入姓名' },
        { label: '电话', prop: 'tel', placeholder: '请输入电话号码' },
        { label: '邮箱', prop: 'email', placeholder: '请输入邮箱' },
        { label: '产业类型', prop: 'type', placeholder: '请输入产业类型' },
        {
          label: '公司名称/店家名称',
          prop: 'companyName',
          placeholder: '请输入公司名称/店家名称'
        },
        { label: '地址', prop: 'address', placeholder: '请输入地址' },
        { label: '城市', prop: 'city', placeholder: '请输入城市' }
      ]
    }
  },
  data() {
    const dragVerify = (rule, value, callback) => {
      if (!this.isPassing) {
        callback(new Error(this.$t("MerchantZone['请拖动滑块完成验证']")))
      } else {
        callback()
      }
    }
    return {
      applicationDialogVisible: false,
      isFullscreen: false,
      isPassing: false,
      successDialogVisible: false,
      labelPosition: 'right',
      applicationForm: {
        name: '',
        tel: '',
        email: '',
        type: '',
        companyName: '',
        address: '',
        city: ''
      },
      typeOptions: [
        {
          label: '美食',
          options: [ { value: '高棉料理', label: '高棉料理' },
            { value: '越南料理', label: '越南料理' },
            { value: '泰国料理', label: '泰国料理' },
            { value: '东南亚料理', label: '东南亚料理' },
            { value: '日韩料理', label: '日韩料理' },
            { value: '中华料理', label: '中华料理' },
            { value: '西餐', label: '西餐' },
            { value: '快餐', label: '快餐' },
            { value: '火锅', label: '火锅' },
            { value: '烧烤', label: '烧烤' },
            { value: '小吃', label: '小吃' },
            { value: '咖啡厅', label: '咖啡厅' },
            { value: '甜点/饮料', label: '甜点/饮料' },
            { value: '烘焙/糕点', label: '烘焙/糕点' } ]
        }, {
          label: '零售',
          options: [ { value: '便利店', label: '便利店' },
            { value: '生鲜蔬果', label: '生鲜蔬果' },
            { value: '数码家电', label: '数码家电' },
            { value: '家具/厨房用具', label: '家具/厨房用具' },
            { value: '时尚', label: '时尚' },
            { value: '户外运动', label: '户外运动' },
            { value: '美容保养', label: '美容保养' },
            { value: '母婴用品', label: '母婴用品' },
            { value: '保健品', label: '保健品' },
            { value: '书店文具', label: '书店文具' },
            { value: '手表和眼镜', label: '手表和眼镜' },
            { value: '宠物用品', label: '宠物用品' },
            { value: '礼品鲜花', label: '礼品鲜花' },
            { value: '批发', label: '批发' },
            { value: '手机', label: '手机' },
            { value: '通讯设备', label: '通讯设备' },
            { value: '超级市场', label: '超级市场' } ]
        }, {
          label: '商业服务',
          options: [ { value: '婚礼/摄影', label: '婚礼/摄影' },
            { value: '装饰/设计', label: '装饰/设计' },
            { value: '居家清洁/维修服务', label: '居家清洁/维修服务' },
            { value: '苗木种植/美化环境', label: '苗木种植/美化环境' },
            { value: '宠物医院', label: '宠物医院' } ]
        }, {
          label: '休闲娱乐',
          options: [ { value: '娱乐票务', label: '娱乐票务' },
            { value: '体育和健身场所', label: '体育和健身场所' },
            { value: '俱乐部/酒吧', label: '俱乐部/酒吧' },
            { value: '娱乐厅/ KTV /网吧', label: '娱乐厅/ KTV /网吧' },
            { value: '电影', label: '电影' },
            { value: '演出活动', label: '演出活动' },
            { value: '理发/美容/美甲沙龙', label: '理发/美容/美甲沙龙' } ]
        }, {
          label: '旅游交通',
          options: [ { value: '租车', label: '租车' },
            { value: '加油站', label: '加油站' },
            { value: '公共交通工具', label: '公共交通工具' },
            { value: '船舶服务', label: '船舶服务' },
            { value: '旅行社', label: '旅行社' },
            { value: '航空公司', label: '航空公司' },
            { value: '汽车配件', label: '汽车配件' },
            { value: '汽车美容', label: '汽车美容' },
            { value: '停车缴费', label: '停车缴费' },
            { value: '旅馆/酒店/度假村', label: '旅馆/酒店/度假村' },
            { value: '旅游景点', label: '旅游景点' } ]
        }, {
          label: '互联网/电脑服务/游戏',
          options: [ { value: '游戏', label: '游戏' },
            { value: '软件/网站/技术开发', label: '软件/网站/技术开发' } ]
        }, {
          label: '教育/医疗',
          options: [ { value: '教育/培训/考试费用/学费', label: '教育/培训/考试费用/学费' },
            { value: '保健设备/医疗设备', label: '保健设备/医疗设备' },
            { value: '药局', label: '药局' },
            { value: '私立医院/诊所', label: '私立医院/诊所' } ]
        }, {
          label: '生活缴费',
          options: [ { value: '电信运营商', label: '电信运营商' },
            { value: '网络费', label: '网络费' },
            { value: '电话费', label: '电话费' },
            { value: '有线电视付款', label: '有线电视付款' },
            { value: '物业管理费', label: '物业管理费' },
            { value: '公用事业', label: '公用事业' } ]
        },
        {
          label: '其他产业',
          options: [ { value: '其他行业', label: '其他行业' }]
        }
      ],
      cityOptions: [
        { value: '金边', label: '金边' },
        { value: '班迭棉吉省', label: '班迭棉吉省' },
        { value: '马德望省', label: '马德望省' },
        { value: '磅湛省', label: '磅湛省' },
        { value: '磅清扬省', label: '磅清扬省' },
        { value: '磅士卑省', label: '磅士卑省' },
        { value: '磅同省', label: '磅同省' },
        { value: '贡布省', label: '贡布省' },
        { value: '干丹省', label: '干丹省' },
        { value: '白马省', label: '白马省' },
        { value: '戈公省', label: '戈公省' },
        { value: '桔井省', label: '桔井省' },
        { value: '蒙多基里省', label: '蒙多基里省' },
        { value: '奥多棉吉省', label: '奥多棉吉省' },
        { value: '拜林省', label: '拜林省' },
        { value: '西哈努克省', label: '西哈努克省' },
        { value: '柏威夏省', label: '柏威夏省' },
        { value: '菩萨省', label: '菩萨省' },
        { value: '波萝勉省', label: '波萝勉省' },
        { value: '腊塔纳基里省', label: '腊塔纳基里省' },
        { value: '暹粒省', label: '暹粒省' },
        { value: '上丁省', label: '上丁省' },
        { value: '柴桢省', label: '柴桢省' },
        { value: '茶胶省', label: '茶胶省' },
        { value: '特本克蒙省', label: '特本克蒙省' }

      ],
      applicationLoading: false,
      applicationFormRule: {
        name: [
          {
            required: true,
            message: this.$t("MerchantZone['请输入姓名']"),
            trigger: 'blur'
          }
        ],
        tel: [
          {
            required: true,
            message: this.$t("MerchantZone['请输入电话号码']"),
            trigger: 'blur'
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t("MerchantZone['请输入邮箱']"),
            trigger: ['blur', 'change']
          }
        ],
        type: [
          {
            required: true,
            message: this.$t("MerchantZone['请输入产业类型']"),
            trigger: 'blur'
          }
        ],
        companyName: [
          {
            required: true,
            message: this.$t("MerchantZone['请输入公司名称/店家名称']"),
            trigger: 'blur'
          }
        ],
        dragVerify: [{ validator: dragVerify, trigger: 'change' }]
      }

    }
  },
  methods: {
    onSubmit() {
      this.$refs.applicationForm.validate(valid => {
        if (valid) {
          this.applicationLoading = true
          this.sendEmail()
        }
      })
    },
    applicationDialogClose() {
      this.isPassing = false
      this.$refs.dragVerify.reset()
      this.$refs.applicationForm.resetFields()
    },
    sendEmail(e) {
      // emailjs 账户信息
      const inf = {
        SERVICE_ID: '12s2s-cno39-N2d32-1223',
        TEMPLATE_ID: 'template_mwjxebg',
        USER_ID: 'user_OwtM7To5VygAT12GSmhBy'
      }
      emailjs
        // .send('<YOUR SERVICE ID>', '<YOUR TEMPLATE ID>', this.applicationForm,USER_ID)
        .send(
          inf.SERVICE_ID,
          inf.TEMPLATE_ID,
          this.applicationForm,
          inf.USER_ID
        )
        .then(
          response => {
            this.applicationDialogVisible = false
            this.$refs.applicationForm.resetFields()
            this.successDialogboxShow()
            // this.$messageBox.alert('已申请', '提示', {
            //   confirmButtonText: '确定',
            //   callback: (action) => {
            //     this.applicationDialogVisible = false
            //     this.$refs.applicationForm.resetFields()
            //   }
            // })
          },
          err => {
            console.log('FAILED...', err)
            this.$messageBox.alert(
              'Application failed, please try again later ',
              'error',
              {
                confirmButtonText: 'OK',
                callback: action => {}
              }
            )
          }
        )
        .finally(() => {
          this.applicationLoading = false
        })
    },
    successDialogboxShow() {
      this.successDialogVisible = true
      setTimeout(() => {
        this.successDialogVisible = false
      }, 2000)
    },
    resetFormStyle() {
      if (document.documentElement.clientWidth < 768) {
        this.labelPosition = 'top'
        this.isFullscreen = true
      } else {
        this.labelPosition = 'right'
        this.isFullscreen = false
      }
    }
  },
  mounted() {
    this.resetFormStyle()
    window.onresize = () => {
      this.resetFormStyle()
    }
  }
}
</script>
<style lang="less" scoped>
.merchant-zone {
  .banner {
    height: 550px;
    background: url("../assets/image/merchantZone/banner@2x.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding-top: 222px;
      padding-left: 110px;
      color: #ffffff;
      strong {
        font-size: 48px;
        line-height: 1.3;
        font-weight: bold;
        color: #ffffff;
      }
      p {
        font-weight: bold;
        margin-top: 13px;
        font-size: 48px;
        color: #ffffff;
        line-height: 48px;
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        padding: 80px 18px 0;
        text-align: center;
        strong {
          font-size: 36px;
        }
        p {
          font-size: 16px;
        }
      }
      height: 400px;
    }
  }
  ::v-deep.panel .content {
    padding-top: 100px;
  }
  .apply {
    text-align: center;
    padding: 120px 0;
    p {
      font-size: 32px;
      margin: auto;
      max-width: 1000px;
      color: #333333;
      line-height: 1.5;
      margin-bottom: 30px;
    }
    button {
      min-width: 140px;
      padding: 0 15px;
      height: 40px;
      background: #e60013;
      border-radius: 6px;
      font-size: 18px;
      color: #ffffff;
      box-sizing: border-box;
      outline: none;
      border: none;
      cursor: pointer;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.8;
      }
    }
    @media screen and (max-width: 765px) {
      padding: 50px 0;
      p {
        font-size: 24px;
        padding: 0 18px;
      }
    }
  }
  .advantage {
    min-height: 700px;
    background: url("../assets/image/merchantZone/商户专区-合作商户优势@2x.jpg")
      no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding-top: 157px;
      text-align: center;
      color: #ffffff;
      h2 {
        margin-bottom: 33px;
        font-size: 40px;
        font-weight: 400;
      }
      i {
        font-size: 25px;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 95px;
        font-size: 14px;
        padding-bottom: 100px;
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 260px;
          margin-bottom: 40px;
          line-height: 1.5;
          img {
            margin-bottom: 30px;
            height: 120px;
            width: 120px;
          }
          strong {
            font-weight: bold;
            min-height: 3em;
          }
          p {
            margin-top: 10px;
            max-width: 260px;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      min-height: 400px;
      padding-bottom: 5px;
      .container {
        padding-top: 45px;
        h2 {
          margin-bottom: 33px;
          font-size: 24px;
        }
        i {
          font-size: 25px;
        }
        .list {
          margin-top: 30px;
          padding-bottom: 45px;
          li {
            display: flex;
            width: 150px;
            img {
              margin-bottom: 30px;
              height: 80px;
              width: 80px;
            }
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }
  .common {
    .use-steps {
      display: flex;
      margin-top: 25px;
      justify-content: center;
      flex-wrap: wrap;
      font-size: 14px;
      li {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 300px;
        margin-bottom: 30px;
        img {
          // height: 392px;
          width: 242px;
          margin-bottom: 50px;
        }
        strong {
          font-weight: bold;
          color: #333333;
        }
        p {
          margin-top: 12px;
          line-height: 1.3;
          color: #333333;
        }
        .arrow {
          text-align: center;
          img {
            height: 16px;
            width: 20px;
            margin-bottom: 90px;
          }
        }
      }
      li:nth-child(2n) {
        width: 0px;
        height: 100px;
        line-height: 100px;
        align-self: center;
      }
    }

    @media screen and (max-width: 768px) {
      /deep/.container {
        max-width: 600px;
        margin: auto;
        .content {
          padding-top: 20px;
        }
      }
      .use-steps {
        margin-top: 20px !important;
        li {
          .arrow {
            img {
              transform: rotate(90deg);
              margin-bottom: 0;
            }
          }
          &:nth-child(2n) {
            width: 300px;
          }
        }
      }
    }
  }
  .paySteps {
    /deep/.content {
      padding-top: 20px;
    }
    h3 {
      text-align: center;
      font-size: 24px;
      padding: 40px 0 40px;
    }

    .addition-text {
      text-align: center;
    }
  }
  .code {
    .code-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .item {
        display: flex;
        width: 270px;
        flex-direction: column;
        align-items: center;
        margin: 0 100px 45px;
        .title {
          font-size: 24px;
          margin-bottom: 30px;
        }
        img {
          height: 150px;
          width: 150px;
        }
        p {
          margin-top: 40px;
          font-size: 14px;
          line-height: 1.5;
          font-weight: bold;
          color: #333333;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .code-box {
        margin: 0 -18px;
        .item {
          width: 160px;
          margin: 0 10px 45px;
          img {
            height: 100px;
            width: 100px;
          }
          p {
            text-align: center;
          }
        }
      }
    }
  }
  .scheme {
    background: #f7f7f7;
    .items-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 200px;
        height: 200px;
        background: #ffffff;
        margin-bottom: 30px;
      }
    }
    @media screen and (max-width: 768px) {
      .items-box {
        margin: 0 -18px;
        justify-content: center;
        .item {
          width: 160px;
          margin: 0 10px 30px;
          height: 160px;
        }
      }
    }
  }
  .problem {
    padding-bottom: 100px;
    .main {
      max-width: 900px;
      margin: auto;
      margin-bottom: 20px;
      border: none;
      .title-text {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 1.5;
        padding: 13px 0;
      }
      /deep/ .el-collapse-item {
        background: #f7f7f7;
        border-radius: 6px;
      }
      /deep/.solution_text {
        padding: 0 30px 10px 20px;
        background: #f7f7f7;
        color: #333333;
        border-radius: 0 0 6px 6px;
        b {
          display: block;
          font-weight: bold;
          padding-top: 10px;
        }
      }
      /deep/.el-collapse-item__content {
        padding-bottom: 0;
      }
      /deep/.el-collapse-item__header {
        padding: 0 10px 0 20px;
        border-radius: 6px;
        border-bottom: none;
        background: #f7f7f7;
        height: unset;
      }
      /deep/.el-collapse-item__wrap {
        border-bottom: none;
      }
    }
  }
  .application-dialog {
    /deep/ .el-dialog {
      border-radius: 6px;
    }
    .form {
      padding-right: 140px;
      /deep/.dv_text {
        font-size: 12px !important;
      }
      .button {
        width: 140px;
        font-size: 18px;
        border-radius: 6px;
        margin-left: 40px;
        margin-top: 20px;
      }
    }
  }
  .successDialogbox {
    text-align: center;
    p {
      margin-top: 20px;
      margin-bottom: 40px;
    }
    /deep/.el-dialog {
      border-radius: 6px;
    }
  }
  @media screen and (max-width: 768px) {
    ::v-deep.panel .content {
      padding-top: 30px;
    }
    .paySteps {
      h3 {
        font-size: 20px;
        padding: 40px 0 40px;
      }
    }
    .problem {
      .main {
        /deep/.solution_text {
          padding: 0 10px 10px 10px;
        }
        /deep/.el-collapse-item__header {
          padding: 0 5px 0 10px;
        }
      }
    }
    .application-dialog {
      touch-action: none;
      touch-action: pan-y;
      /deep/ .el-dialog {
        margin-top: unset !important;
        .el-form--label-top .el-form-item__label {
          line-height: 1;
        }
      }
      .drag-verify {
        margin: auto;
      }
      .form {
        padding-right: unset;
        .drag_verify {
          margin-top: 20px;
        }
        .btn-box {
          text-align: center;
        }
        .button {
          margin: 30px auto;
        }
      }
    }
  }
}
</style>
